<template>
  <div class="card my-3 p-3" :class="{'d-none':!kinderen.length}">
    <h6>Kinderen {{titel}} jaar</h6>
    <opvang v-for="( kind, i) in kinderen"
            :kindopvang="kind"
            :key="i"
            :index="i"
            :warning="warning"
            @opvang="update"/>

  </div>
</template>
<script>
import Opvang from '@/components/Opvang.vue';

export default {
  props: ['name','kinderen','warning'],
  components: {
    Opvang,
  },
  methods: {
    update(e) {
      this.opvangdata[e.index] = e.data;
      this.$emit('opvangdata', {name: this.name, opvangdata:this.opvangdata})
    }
  },
  data() {
    return {
      opvangdata: [],
    }
  },
  computed: {
    titel() {
      let translator = {kind0: '0 tot 4', kind4: '4 tot 12', kind12: '12 tot 16', kind16: '16 tot 17'};
      return translator[this.name];
    },
  },
  mounted() {
    this.opvangdata = this.kinderen;
  }
}
</script>