<template>
  <div class="logo gemeentelogo" @click="loguit()">
    <img v-if="logo" class="logo" :src="logo" alt="logo">
  </div>
</template>

<script>
export default {
  computed: {
    gemeente() {
      return this.$store.state.gemeente;
    },
    logo() {
      return 'https://www.berekenuwrechtplus.nl/logo/logo' + this.gemeente.gemcode + '.' + this.gemeente.extensie;
    },
  }
}
</script>

<style scoped>
.logo {
  max-height: 60px;
  max-width: 300px;
}

.gemeentelogo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 420px;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 3px;
}
</style>