<template>
  <div id="app">
    <werkloont/>
  </div>
</template>

<script>
import Werkloont from './components/Werkloont.vue'

export default {
  name: 'app',
  components: {
    Werkloont
  }
}
</script>

<style>

</style>
