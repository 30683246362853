import Vue from 'vue';
import store from "./store";
import App from './App.vue'
import axios from "axios";
import Kindgroep from '@/components/Kindgroep.vue';
import Login from './components/Login';
import Rekenhulp from './components/Rekenhulp';
import Dossiers from './components/Dossiers';
import Dossiernavigatie from './components/Dossiernavigatie';
import VTooltip from 'v-tooltip'
import {BootstrapVue} from 'bootstrap-vue'
import {InlineSvgPlugin} from 'vue-inline-svg';

/* eslint-disable */
const AxiosConfig = require('./configs/config.axios');

Vue.use(VTooltip);
Vue.prototype.$axios = axios;
Vue.use(BootstrapVue);
Vue.use(InlineSvgPlugin);

Vue.component('dossiernavigatie', Dossiernavigatie);
Vue.component('dossiers', Dossiers);
Vue.component('kindgroep', Kindgroep);
Vue.component('login', Login);
Vue.component('rekenhulp', Rekenhulp);

Vue.directive('focus', {
    // When the bound element is inserted into the DOM...
    inserted: function (el) {
        // Focus the element
        el.focus()
    }
})

const kinderopvangToComma = (kinderopvang) => {
    const kidsgroups = Object.keys(kinderopvang);
    kidsgroups.forEach(kidgroup => kinderopvang[kidgroup].forEach(
        kid => kid[2] = kid[2].toString().replace('.', ',')));
    return kinderopvang;
}
const formatObject = (obj, side) => {
    const nwObj = {};
    for (let k in obj) {
        if (typeof (obj[k]) === 'string') {
            if (side === 'euro')
                nwObj[k] = obj[k].replace('.', ',')
            else
                nwObj[k] = obj[k].replace(',', '.')
        } else {
            nwObj[k] = obj[k]
        }
    }
    return nwObj;
}

const formatRegelingen = (regelingen, side) => {
    if (regelingen) {
        const nw = [];
        regelingen.forEach((r) => {
            const temp = Object.assign({}, r);
            if (side === 'euro') {
                temp.jaarbedrag = r.jaarbedrag ? r.jaarbedrag.replace('.', ',') : null;
                temp.bedrag = r.bedrag ? r.bedrag.replace('.', ',') : null;
            } else {
                temp.jaarbedrag = r.jaarbedrag ? r.jaarbedrag.replace(',', '.') : null;
                temp.bedrag = r.bedrag ? r.bedrag.replace(',', '.') : null;
            }
            nw.push(temp);
        });
        return nw;
    }
}


Vue.mixin({
    methods: {
        afgerond(decimal) {
            return Math.round(decimal * 100) / 100;
        },
        dateTimeFormat(str) {
            return new Date(str).toLocaleDateString('nl-nl', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: "numeric",
                minute: "numeric"
            });
        },
        decimaalBedrag(str) {
            if(str === 0 || str ==="0") return str;
            if (str) {
                str = str.toString().replace('.', ',').replace(/[^\d,]/g, '');
                let count = (str.match(/,/g) || []).length;
                if (count > 1) str = str.replace(',', '');
                let parts = str.split(',')
                if (parts[1] && parts[1].length > 2) {
                    parts[1] = parts[1].substring(0, 2);
                }
                parts[0] = parts[0].replace(/^0*/g, '');
                return parts.join(',');
            }
            return str;
        },
        integerBedrag(str) {
            return str.toString().replace(/[^\d]/g, '');
        },
        kinderopvangToDot(kinderopvang) {
            const kidsgroups = Object.keys(kinderopvang);
            kidsgroups.forEach(kidgroup => kinderopvang[kidgroup].forEach(
                kid => kid[2] = parseFloat(kid[2].toString().replace(',', '.'))));
            return kinderopvang;
        },
        maakBedrag(str) {
            if (typeof (str) === 'string') {
                return str.replace(',', '.');
            }
            return str;
        },
        openDossier(id) {
            this.$store.commit('setId', id);
            let self = this
            this.$axios
                .get(`/api/werkloont/${id}`)
                .then(response => {
                    self.$store.dispatch('loadDossier', response.data).then(() => {
                        self.id = self.$store.state.id;
                        self.$store.state.session.send = true;
                    })
                })
                .catch((e) => console.log('error get: ' + e));
        },
    }
});

export {kinderopvangToComma, formatObject, formatRegelingen};

//dit MOET onderaan staan: het renderen van de Vue instantie met alle componenten
new Vue({
    store: store,
    render: h => h(App),
}).$mount('#app');



