<template>
  <div class="mx-4 mt-5 p-4 border">
    <h6 class="text-center mb-4">Rekenhulp inkomstenvrijlating</h6>
    <div class="form-group row">
      <p class="required col-sm-6"
         :class="{'text-danger': submit &&  vrijlatingspercentage === ''}">
        Vrijlagingpercentage:</p>
      <div class="col-sm-6">
        <label>
          <input class="radio-inline" type="radio" name="vrijlatingspercentage"
                 v-model="vrijlatingspercentage"
                 value="hoog"> hoog <small class="dimmed ml-2">(25%)</small>
        </label>
        <label>
          <input class="radio-inline ml-3" type="radio" name="vrijlatingspercentage"
                 v-model="vrijlatingspercentage"
                 value="laag"> laag <small class="dimmed ml-2">(12,5%)</small>
        </label>
      </div>
    </div>

    <div class="form-group row">
      <p class="required col-sm-6"
         :class="{'text-danger': submit &&  !this.inkomen > 0}">
        Het inkomen tijdens bijstand:</p>
      <div class="col-sm-6 position-relative">
        <span class="euro">€ </span>
        <input class="form-control" type="text" name="inkomen"
               v-model="inkomen">
      </div>
    </div>
    <div class="form-group row">
      <p class="required col-12 text-danger" v-if="submit && !this.ingevuldAanvragerLeeftijd"> Leeftijd aanvrager is niet gekozen</p>
    </div>
    <div class="form-group row">
      <p class="required col-12 text-danger" v-if="submit && !this.ingevuldPartnerLeeftijd"> Leeftijd partner is niet gekozen</p>
    </div>
    <div class="d-flex justify-content-center h-25 mt-4 mb-2">
      <input class="btn btn-primary" type="button" value="Naar berekening op nieuw tabblad" @click="bereken()">
    </div>
  </div>

</template>

<script>

import axios from "axios";

export default {
  props: ['leefvormdata', 'ingevuldAanvragerLeeftijd', 'ingevuldPartnerLeeftijd'],
  data() {
    return {
      vrijlatingspercentage: '',
      inkomen: 0,
      submit: false,
    }
  },
  computed: {
    leefvormdataToString() {
      let str = '';
      for (let key in this.leefvormdata) {
        str += '&' + key + '=' + this.leefvormdata[key];
      }
      return str;
    },
  },
  methods: {
    bereken() {
      this.submit = true;
      if (this.vrijlatingspercentage !== '' && this.inkomen > 0 && this.ingevuldAanvragerLeeftijd && this.ingevuldPartnerLeeftijd) {
        const url = axios.defaults.baseURL + `/api/inkomstenvrijlating?hoogteVrijlatingPercentage=` + this.vrijlatingspercentage + `&inkomen=` + this.inkomen + this.leefvormdataToString;
        window.open(url, '_blank');
        this.$parent.session.rekenhulp = false;
      }
    }
  }
}
</script>
<style lang="scss" src="./Werkloont/werkloont.scss" scoped></style>