import Vue from 'vue';
import Vuex from "vuex";
import axios from "axios";
import { kinderopvangToComma, formatObject, formatRegelingen} from "@/main";

Vue.use(Vuex);

const defaultState = () => {
  return {
    loading: true, // om een laadscherm te tonen
    loggedIn: false, // gebruiker is ingelogd, maar heeft misschien geen toegang tot deze module
    authenticated: '', // gebruiker heeft toegang tot deze module
    authenticationMessage: '', // Error bij het inloggen
    user:{},

      aanvrager: {
        betaalde_alimentatie: 0,
        bruto_maandloon: 0,
        bruto_jaarloon: 0,
        dertiende_maand: 0,
        eenmalige_uitkering: 0,
        fiscaal_jaarloon: 0,
        fiscaal_maandloon: 0,
        kostendelerscorrectie: 0,
        inkomstenvrijlating: 0,
        leeftijd: '',
        loonheffing: 0,
        loon: 0,
        loonsoort: '',
        maanden: '',
        netto_maandloon: 0,
        ontvangen_alimentatie: 0,
        ontvangen_kinderalimentatie: 0,
      periode: 'M',
        totaal_inkomen: 0,
        uren_werk_per_week: 0,
        vakantiegeld: 0,
        vakantiegeld_percentage: 0,
        verwervingskosten: 0,
      },
      armoedeval: {
        bijstand: 0,
        resultaat: 0,
        werk: 0,
      },
      bedragenarmoedeval: [],
      combinatiekorting: {
        bedrag: 0,
        inkomenMinstverdienende: 0,
        maximum: 0,
        minimuminkomen: 0,
        percentage: 0,
      },
      gemeente: {
        extensie: '',
        gemcode: 0,
        naam: '',
        handleidingurl: '',
      },
      huurtoeslag: {
        bedrag: 0,
        bedragbijstand: 0,
        aftoppingsgrens: 0,
        basishuur: 0,
        basishuurberekend: 0,
        huishouden: 0,
        inkomensijkpunt: 0,
        kwaliteitskortingsgrens: 0,
        maxhuur: 0,
        normhuur: 0,
        normhuurverhoging: 0,
        deel1: 0,
        deel2: 0,
        deel3: 0,
        minimumdeel1: 0,
        minimumdeel2: 0,
        minimumdeel3: 0
      },
      huurwoning: {
        huur: '',
        woningaanpassing: false
      },
      id: 0,
      kenmerk: 0,
      kopieen: [],
      inkomenuitbijstand: 0,
      kind0: 0,
      kind4: 0,
      kind12: 0,
      kind16: 0,
      kindgebondenbudget: {
        deel1: 0,
        deel2: 0,
        deel3: 0,
        deel4: 0,
        drempelinkomen: 0,
        grens: 0,
        kindgebondenbudget: 0,
        kind12: 0,
        kind16: 0,
        ophoging12jaar: 0,
        ophoging16jaar: 0,
        verlagingmeerinkomenpercentage: 0,
        verlaging: 0
      },
      kindopvang: {kind0: [], kind4: [], kind12: [], kind16: []},
      kinderopvang: {
        kind1bedrag: 0,
        kind1index: 0,
        kinderopvang: 0,
        percentages: [],
        kindrestbedrag: 0,
        kosten: [],
        kostentotaal: 0,
        kostenvergoed: [],
        ondergrens: 0
      },
      koopwoning: {
        eigenwoningforfait: 0,
        financieringskosten: 0,
        hypotheekrente: 0,
        hypotheekrentejaar: 0,
        max: 0,
        ondergrens: 0,
        perc: 0,
        plus: 0,
        woz: 0
      },
      nav: {
        ck: false,
        ht: false,
        kb: false,
        kt: false,
        ii: false,
        zt: false,
      },
      loonsoorten: ['N', 'B', 'F'],
      partner: {
        betaalde_alimentatie: 0,
        bruto_maandloon: 0,
        bruto_jaarloon: 0,
        dertiende_maand: 0,
        eenmalige_uitkering: 0,
        fiscaal_jaarloon: 0,
        fiscaal_maandloon: 0,
        leeftijd: '0',
        loonheffing: 0,
        loon: 0,
      looninvoer: 0,
        loonsoort: '',
        maanden: '0',
        netto_maandloon: 0,
        ontvangen_alimentatie: 0,
        ontvangen_kinderalimentatie: 0,
      periode: 'M',
        totaal_inkomen: 0,
      uren_werk_per_week: 0,
        vakantiegeld: 0,
        vakantiegeld_percentage: 0,
      },
      regelingen: [],
    saved: false,
      recht: [],
    session: {
      error422: '',
      errorMessage: '',
      kinderen_aanwezig: '',
      loaded: false,
      loggedIn: false,
      partner_aanwezig: '',
      rekenhulp: false,
      saved: false,
      send: false,
      woning: '',
    },
      toeslagenpermaand: {
        bijstand: 0,
        werk: 0,
    },
    toetsingsinkomen: {
      aanvrager: 0,
      eigenwoningforfait: 0,
      eigenwoningforfait_partner: 0,
      financieringskosten: 0,
      financieringskosten_partner: 0,
      gezinsinkomenmaand: 0,
      hypotheekrente: 0,
      hypotheekrente_partner: 0,
      inkomenuitbijstand: 0,
      partner: 0,
      totaal: 0,
      totaal_inkomen_aanvrager: 0,
      totaal_inkomen_partner: 0,
    },
    zorgtoeslag: {
      bedrag: 0,
      bedragbijstand: 0,
      drempelinkomen: 0,
      normpremie: 0,
      standaardpremie: 0,
    }
  }
}


// eslint-disable-next-line no-unused-vars


export default new Vuex.Store({
  namespaced: true,
  state: defaultState(),
  getters: {
    getLoggedIn(state) {
      return state.session.loggedIn;
    },
    getState(state) {
      return state;
    },
    getDefaultState() {
      return defaultState();
    },
    getLoaded(state) {
      return state.session.loaded;
    },
    // new
    loading(state){
      console.log('loading 11');
      return state.loading;
    },
    authenticated(state){
      return state.authenticated
    },
    authenticationMessage(state) {
      return state.authenticationMessage;
    },
    user(state){
      return state.user
    },

  },
  mutations: {
    resetPartner(state) {
      let partner = defaultState().partner;
      for (const prop in partner) {
        state.partner[prop] = partner[prop];
      }
    },
    resetRegelingen(state) {
      state.regelingen.forEach(r => {
        r.bedrag = '';
        r.gebruiken = false;
        r.jaarbedrag = '';
      });
    },
    setDossier(state, payload) {
      for (let key in payload) {
        if (key === 'aanvrager' && !payload[key].inkomstenvrijlating) {
          payload[key].inkomstenvrijlating = 0;
        }
        if (key === 'kindopvang') {
          state[key] = kinderopvangToComma(payload[key])
        } else if (['aanvrager', 'partner', 'huurhuis'].includes(key)) {
          state[key] = formatObject(payload[key], 'euro');
        } else if (key === 'regelingen') {
          state[key] = formatRegelingen(payload[key], 'euro');
        } else {
          state[key] = payload[key];
        }
      }
    },
    setGemeente(state, payload) {
      state.gemeente = payload;
    },
    setId(state, payload) {
      state.id = payload;
    },
    setKopieen(state, payload) {
      state.kopieen = payload;
    },
    setLoggedIn(state, payload) {
      state.session.loggedIn = payload;
    },
    setRegelingen(state, payload) {
      state.regelingen = payload;
    },
    setState(state) {
      state.session.partner_aanwezig = state.aanvrager.leeftijd == '0' && state.partner.leeftijd == '0' ? '' : (state.partner.leeftijd == '0' ? '0' : '1');
      let kinderen = 0
      Object.keys(state.kindopvang).forEach(k => {
        kinderen += state.kindopvang[k].length;
      })
      state.session.kinderen_aanwezig = state.aanvrager.leeftijd == '0' ? '' : (kinderen > 0 ? '1' : '0');
      state.session.woning = state.huurwoning.huur > 0 ? '0' : (state.koopwoning.hypotheekrente > 0 ? '1' : '');
      state.session.send = true;
      state.session.loaded = true;
    },
    SET_LOADING (state, value) {
      state.loading = value
    },
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },
    SET_ERROR (state, value) {
      state.authenticationMessage = value
    },
    SET_LOGGEDIN (state, value) {
      state.loggedIn = value
    },
    SET_GEBRUIKER (state, data) {
      state.user = data
    },
    SET_GEMEENTE (state, data) {
      state.gemeente = data
    },
  },
  actions: {
    loadDossier(store, payload) {
      store.commit('setDossier', payload);
      store.commit('setState');
    },
    signOut({commit}){
      return axios.get('/api/uitloggen').then(() => {
        commit('SET_GEBRUIKER', {})
        commit('SET_ERROR', '')
        commit('SET_GEMEENTE', {})
        commit('SET_AUTHENTICATED', false)
        commit('SET_LOGGEDIN', false)
      }).catch(() => {
        console.error('Uitloggen mislukt');
      });
    },
    async signIn ({ commit }) {
      commit('SET_LOADING', true);
      return axios.get('/api/gebruiker').then((response) => {
        if(Object.keys(response.data).length > 0){
          commit('SET_AUTHENTICATED', true)
          commit('SET_ERROR', '')
          commit('SET_GEBRUIKER', response.data.user)
          commit('SET_GEMEENTE', response.data.gemeente)
          commit('SET_LOADING', false);
        } else {
          commit('SET_AUTHENTICATED', false)
          commit('SET_ERROR', 'Inloggen mislukt')
          commit('SET_LOGGEDIN', false)
          commit('SET_GEBRUIKER', {})
          commit('SET_LOADING', false);
        }
      }).catch((error) => {
        if(error.response.status === 403){
          commit('SET_ERROR', 'U heeft geen toegang tot deze module');
        }
        commit('SET_AUTHENTICATED', false)
        commit('SET_LOGGEDIN', false)
        commit('SET_GEBRUIKER', {})
        commit('SET_LOADING', false);
      });
    },
    setLoggedIn({commit}){
      commit('SET_LOGGEDIN', true)
    }
  }
});