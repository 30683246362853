<template>
  <div class="card card-dossier mt-5">
    <div class="card-header d-flex">

      <h2 class="w-50">Dossiers</h2>
      <div v-if="dossiers.length > 5" class="w-50 p-3">
        <input v-model="search" class="form-control" placeholder="filter op kenmerk">
      </div>
    </div>

    <div>
      <button class="btn btn-primary float-right m-4" @click="nieuwDossier">Nieuw dossier</button>
    </div>

    <div class="card-body">
      <table v-if="dossiers.length" class="table table-striped table-bordered">
        <thead>
        <tr>
          <th @click="sort('code')">kenmerk</th>
          <th class="text-right"></th>
          <th @click="sort('updated_at')" class="text-right"></th>
          <th>versies</th>
        </tr>
        </thead>

        <tbody>
        <tr :key="i" v-for="(dossier, i) in filteredDossiers">

          <td class="link" @click="openDossier(dossier.id)" :title="'dossier ' + dossier.kenmerk + ' openen'">
            <span :class="{'opgeslagen': dossier.opgeslagen === 1}">{{ dossier.kenmerk }}</span>
          </td>

          <td v-if="dossier.kopieen && dossier.kopieen.length < 7" class="text-right"
              :title="'dossier ' + dossier.kenmerk + ' kopieren'">
            <inline-svg :src="require('@/assets/copy.svg')" @click="copy(dossier.id, dossier.kenmerk)"/>
          </td>
          <td v-else></td>

          <td class="text-right">{{ dateTimeFormat(dossier.updated_at) }}</td>

          <td class="w-50" @click="show(i)">
            <table v-if="dossier.kopieen" class="mt-4 w-100">
              <tr v-for="(dos,k) in dossier.kopieen" :key="k" @click="openDossier(dos.id)" class="link bg-transparent">
                <td class="link" :title="'dossier ' + dos.kenmerk + ' openen'">
                  <span :class="{'opgeslagen': dossier.opgeslagen === 1}">{{ dos.kenmerk }}</span>
                </td>
                <td class="text-right">{{ dateTimeFormat(dos.updated_at) }}</td>
              </tr>
            </table>
          </td>


        </tr>
        </tbody>

      </table>
    </div>
  </div>

</template>

<script>

export default {
  name: 'Dossiers',
  props: ['gemcode'],
  data() {
    return {
      dossiers: [],
      search: '',
      showVersies: [],
    }
  },
  computed: {
    filteredDossiers() {
      let search = this.search;
      let parents = Object.keys(this.parentWithChildren);
      let result = null
      parents.forEach((parent) => {
        if (parseInt(parent) === parseInt(search)) result = parent;
        if (this.parentWithChildren[parent].includes(parseInt(search))) {
          result = parent;
        }
      });

      return search.length ? this.dossiers.filter(dossier => parseInt(dossier.kenmerk) === parseInt(result)) : this.dossiers;
    },
    parentWithChildren() {
      const result = {};
      this.dossiers.forEach(x => result[x.kenmerk] = x.kopieen.map(dosssier => dosssier.kenmerk))
      return result;
    }
  },
  methods: {
    copy(id, kenmerk) {
      if (confirm('dossier ' + kenmerk + ' kopieëren?')) {
        this.$axios
          .post("/api/kopie", {id: id})
          .then(() => {
            this.haalDossiers();
          })
          .catch((e) => console.log(e));
      } else {
        return false;
      }
    },
    haalDossiers() {
      this.$store.commit('SET_LOADING', true);
      this.$axios
        .get("/api/werkloont", {params: {gemcode: this.gemcode}})
        .then(response => {
          this.dossiers = response.data;
          this.$parent.session.send = true;
          this.$store.commit('SET_LOADING', false);
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit('SET_LOADING', false);
        });
    },
    nieuwDossier() {
      this.$axios
        .post("/api/werkloont", {gemcode: this.gemcode})
        .then(response => {
          this.$store.dispatch('loadDossier', response.data).then(() => {
            this.kenmerk = this.$store.state.kenmerk;
          })
        })
        .catch((e) => console.log('error post: ' + e));
    },
    show(i) {
      if (this.showVersies.length > 0) {
        this.showVersies = [];
      } else {
        this.showVersies.push(i);
      }
    },
    sort(sortKey) {
      this.reverse = (this.sortKey == sortKey) ? !this.reverse : false;
      this.sortKey = sortKey;
    },
  },
  mounted() {
    this.haalDossiers();
  }
}
</script>

<style lang="scss" src="./Werkloont/werkloont.scss" scoped></style>
<style lang="scss" scoped>
td img {
  width: 20px;
}

.card-dossier {
  max-width: 800px;
  margin: 0 auto;
}

.link:hover {
  cursor: pointer;
  background-color: #CFEDF1;
}

svg {
  fill: #A1DFE0;
  width: 20px;
  height: 20px;
}

svg:hover {
  cursor: pointer;
  fill: #00A8AB;
}
.opgeslagen {
  color: #00A8AB;
  font-weight: bold;
}
</style>