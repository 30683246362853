<template>
  <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
    <div class="card card-signin my-5">
      <div class="card-body">
        <h3 class="card-title text-center">Inloggen</h3>
        <form class="form-signin" v-on:submit.prevent>

          <div class="form-group mt-3 mb-3">
            <label for="username" class="font-weight-bold">Gebruikersnaam</label>
            <input type="text" v-model="auth.username" placeholder="gebruikersnaam"  name="username" id="username" class="form-control" autofocus autocomplete="username">
          </div>

          <div class="form-group mt-3 mb-3">
            <label for="password" class="font-weight-bold">Wachtwoord</label>
            <input type="password" v-model="auth.password" placeholder="wachtwoord" name="password" id="password" class="form-control" autocomplete="current-password">
          </div>

          <button class="btn btn-lg btn-primary btn-block mb-4" type="submit" :disabled="processing" @click="login">
            {{ processing ? "Even wachten a.u.b" : "Inloggen" }}
          </button>
          <div v-if="message" class="alert alert-danger text-center" v-html="message"></div>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: "login",
  data() {
    return {
      auth: {
        username: "",
        password: ""
      },
      processing: false,
      gemeente: {},
      gebruikersnaam: '',
      wachtwoord: '',
      jaar: 2021
    }
  },
  computed: {
    gemeenteUitUrl() {
      let gemeenteUitUrl = window.location.href.match(/[^/]*$/);
      return gemeenteUitUrl[0];
    },
    message(){
      return this.$store.getters.authenticationMessage;
    }
  },
  methods: {
    ...mapActions({
      signIn: 'signIn',
      setLoggedIn: 'setLoggedIn',
    }),
    async login() {
      // this.processing = true
      await this.$axios.get('/api/sanctum/csrf-cookie');
      if(this.auth.username.length && this.auth.password.length) {
        let authdata = {
          username: this.auth.username,
          password: this.auth.password
        }

        await this.$axios.post('/login', authdata).then(({data}) => {
          this.setLoggedIn();
          this.$store.commit('SET_LOGGEDIN', true)
          let should_redirect_to_2fa = data.two_factor;
          if (!should_redirect_to_2fa) {
            this.signIn();
          }
        }).catch(({response: {data}}) => {
          var errors = data.errors;
          this.$store.commit('SET_ERROR', 'Inloggen mislukt');
          console.log(errors);
        }).finally(() => {
          this.processing = false
        });
      } else {
        this.$store.commit('SET_ERROR','Aanmelden niet mogelijk zonder inloggegevens');
        this.$store.commit('SET_LOADING', false);
      }
    },

  },
  mounted() {
    this.signIn();
  }, watch: {
    jaar: function () {
      this.$store.commit('setJaar', this.jaar);
    },

  }
}
</script>
<style lang="scss" src="./Werkloont/werkloont.scss" scoped></style>