import Vue from 'vue';
import axios from 'axios';

/**
 * Axios
 */


const apiHost = function(){
  console.log(location.host);

  // local Jon
  if(location.host.substring(0,18) === 'werkloont.bur.test' || location.host.substring(0,8) === 'bur.test'){
    console.log('Connected to: LOCAL API - Jon');
    return location.protocol+'//bur.test';
  }

  // Dev
  if(location.host.substring(0,25) === 'dev.berekenuwrechtplus.nl'){
    console.log('Connected to: DEV API');
    return location.protocol + '//dev.berekenuwrechtplus.nl';
  }

  // Dev
  if(location.host.substring(0,14) === 'www.burplus.nl'){
    console.log('Connected to: ACCEPTATIE API');
    return location.protocol + '//www.burplus.nl';
  }

  // Productie
  return process.env.VUE_APP_URL;
}


  const XdebugParameter = (process.env.NODE_ENV !== 'production')?'PHPSTORM':'';


if (XdebugParameter && document.cookie.indexOf('XDEBUG') !== -1) { // Cookie moet bestaan, Gebruik hiervoor Xdebug Helper Chrome Extension
  axios.interceptors.request.use((config) => {
    config.params = config.params || {};
    config.params['XDEBUG_SESSION_START'] = XdebugParameter;
    return config;
  });
}

axios.defaults.withCredentials = true;
axios.defaults.baseURL = apiHost();

Vue.prototype.$axios = axios;

