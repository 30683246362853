<template>
  <div>
    <div class="card dossier">
      <div class="card-body d-flex">
      <span>
        <strong>Dossier {{ kenmerk }}</strong>
      </span>
        <span class="no-print">
          <ul v-if="Object.keys(kopieen).length">
            <li>verwant: </li>
              <li
                v-for="(id, i) in kopieen"
                :key="i"
                @click="switchDossier(id)"
                class="link"
                :title="'dossier ' + kopieen[i] + ' openen'">
                {{ i }}
              </li>
          </ul>
      </span>
        <span class="no-print link ml-auto" @click="terug()">&laquo; terug</span>
      </div>
      <p class="alert alert-success position-absolute" v-if="changed">Dossier <strong>{{ kenmerk }}</strong> is geladen</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['kopieen', 'kenmerk'],
  data() {
    return {
      changed: false,
      toonverwant: false
    }
  },
  methods: {
    switchDossier(id) {
      this.changed = true;
      this.openDossier(id);
      setTimeout(() => {
        this.changed = false;
      }, 2000);
    },
    terug() {
      this.$parent.id = 0;
      this.$store.commit('resetRegelingen');
      this.$parent.session.loaded = false;
      this.$parent.session.send = false;
      this.$parent.session.saved = false;
      this.$parent.session.error422 = null;
      this.$parent.session.errorMessage = null;
      this.$parent.session.rekenhulp = false;
    }
  }
}
</script>

<style lang="scss" src="./Werkloont/werkloont.scss" scoped></style>
<style lang="scss" scoped>
li {
  margin-left: 20px;
  float: left;
  list-style: none;
}

.link {
  cursor: pointer;
  color: #00A8AB;

  &:hover {
    color: inherit;
  }
}

.link:hover {
  cursor: pointer;
  color: #000;
}

p {
  top: 8px;
  left: 8px;
  background-color: #CFEDF1;
}

strong {
  font-weight: 600;
}
</style>