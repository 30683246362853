<template>
  <div class="card bg-light p-3 mb-4">
    <div class="row card-body">
      <div class="col-md-6 form-group">
    <h6 :class="{'text-danger': warning && kindopvang[0] === null}" class="mb-4">Kind {{ index + 1 }} heeft</h6>
        <div class="d-flex flex-column">
          <label class="radio">
            <input type="radio" v-model="kindopvang[0]" value="N" @change="reset()"> geen opvang
          </label>
          <label class="radio">
            <input type="radio" v-model="kindopvang[0]" value="D"> dagopvang
          </label>
          <label class="radio">
            <input type="radio" v-model="kindopvang[0]" value="B"> buitenschoolse opvang
          </label>
          <label class="radio">
            <input type="radio" v-model="kindopvang[0]" value="G"> gastouder opvang
          </label>
        </div>
      </div>
      <div class="col-md-6">
        <div v-if="kindopvang[0] !== 'N'">
          <div class="form-group">
            <label class="required col-form-label" :class="{'text-danger': warning &&  kindopvang[1] === ''}">
              Hoeveel uur per maand?</label>
            <div class="uurprijs">
              <input class="form-control text-right" type="text" v-model="kindopvang[1]">
            </div>
          </div>
          <div class="form-group">
            <label class="required col-form-label"
                   :class="{'text-danger': warning && kindopvang[2] === ''}">Wat is de uurprijs?</label>
            <div class="uurprijs">
              <input class="form-control text-right" type="text" v-model="kindopvang[2]">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['index', 'kindopvang', 'warning'],
  methods: {
    reset() {
      this.kindopvang[1] = '0';
      this.kindopvang[2] = '0';
    }
  },
  watch: {
    kindopvang: function () {
      this.kindopvang[1] = this.integerBedrag(this.kindopvang[1]);
      this.kindopvang[2] = this.decimaalBedrag(this.kindopvang[2]);
      this.$emit('opvang', {index: this.index, data: this.kindopvang})
    },
  }
}
</script>
